<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DE LOS CORREOS:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-envelope-open fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaCorreos.length}}</span>
                            <br>
                            <span class="text-muted">Correos Enviados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de correos</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col v-if=" checkPermissions('014-EMA-CON','c')==1"  cols="4" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarCorreo = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo correo</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Correos registrados</span>
                </CCardHeader>
                <CCardBody>

                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                       <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12">
                            <b-table hover bordered show-empty mediun responsive outlined :items="listaCorreos" :fields="camposCorreos" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button  size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalDetalleCorreo(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                           <i class="fas fa-search  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button v-if=" checkPermissions('014-EMA-CON','d')==1" size="sm" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCorreo(param)" variant="danger" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                                <template v-slot:cell(fecha)="data">
                                    <p>{{data.item.fecha}} a las {{data.item.hora}}</p>
                                </template>
                                <template v-slot:cell(mensaje)="data">
                                    <div :inner-html.prop="data.item.mensaje | truncate(30)"></div>
                                </template>
                                <template v-slot:cell(tipo)="data">
                                    <span v-if="data.value == 1">Correo</span>
                                    <span v-else-if="data.value == 2">Cuestionario</span>
                                    <span v-else-if="data.value == 3">Incidencia</span>
                                    <span v-else-if="data.value == 4">No Conformidad</span>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-row>
                        <b-col md="6">
                            <span class="my-0 text-muted">Mostrando {{paginaActual}} a {{porPagina}} de {{filasTotales}} registros</span>
                        </b-col>
                        <b-col md="6">
                            <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                        </b-col>
                    </b-row>
                    <!-- <span align="left" class="my-0 text-muted in-line">Mostrando 31 a 40 de {{filasTotales}} registros</span> -->
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarCorreo" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-envelope fa-md"></i>
                <span class="h5"> Envio de correo</span></h6>
            <CButtonClose @click="modalRegistrarCorreo = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarCorreo)">
                <b-row>
                    <b-col lg="6">
                        <validation-provider name="asunto" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Asunto:" class="mb-2">
                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el asunto" v-model="datosCorreo.asunto"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-input size="md" type="date" :state="getValidationState(validationContext)" placeholder="Ingrese la fecha" v-model="datosCorreo.fecha"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="hora" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Hora:" class="mb-2">
                                <b-form-input type="time" size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la hora" v-model="datosCorreo.hora"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12">
                        <validation-provider name="destinatarios" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Destinatarios:" class="mb-2">
                                <b-form-textarea v-model="datosCorreo.destinatarios" :state="getValidationState(validationContext)" placeholder="Ingrese el destinatario ejem: correo1@dominio.com,correo2@dominio.com,correo3@dominio.com ..." rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <b-form-group label="Archivo:" class="mb-2">
                            <b-form-file ref="file" v-model="datosCorreo.archivoMeta" v-on:change="handleFileUpload" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir"></b-form-file>
                        </b-form-group>
                    </b-col>
                    <b-col lg="12">
                        <b-form-group label="Mensaje:" class="mb-2">
                            <quill-editor ref="quillCorreo" v-model="datosCorreo.mensaje" :options="quillConfig">
                            </quill-editor>
                        </b-form-group>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Enviar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarCorreo = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
    <CModal :show.sync="modalDetalleCorreo" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-envelope-open fa-md"></i>
                <span class="h5"> Detalle del correo</span></h6>
            <CButtonClose @click="modalDetalleCorreo = false" class="text-white" />
        </template>
        <b-row>
            <b-col lg="6">
                <b-form-group label="Asunto:" class="mb-2">
                    <b-form-input size="md" disabled class="disabled-white" placeholder="Ingrese el asunto" v-model="datosDetalleCorreo.asunto"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="3">
                <b-form-group label="Fecha:" class="mb-2">
                    <b-form-input size="md" disabled class="disabled-white" type="date" placeholder="Ingrese la fecha" v-model="datosDetalleCorreo.fecha"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="3">
                <b-form-group label="Hora:" class="mb-2">
                    <b-form-input size="md" disabled class="disabled-white" placeholder="Ingrese la hora" v-model="datosDetalleCorreo.hora"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="12">
                <b-form-group label="Destinatarios:" class="mb-2">
                    <b-form-textarea disabled class="disabled-white" v-model="datosDetalleCorreo.destinatario" placeholder="Ingrese el destinatario" rows="3" max-rows="6"></b-form-textarea>
                </b-form-group>
            </b-col>
            <b-col lg="12" v-if="datosDetalleCorreo.urlArchivo != null">
                <b-form-group label="Archivo:" class="mb-2">
                    <b-button @click="descargarDocumento(datosDetalleCorreo.urlArchivo)" variant="dark">{{datosDetalleCorreo.nombreArchivo}}</b-button>
                </b-form-group>
            </b-col>
            <b-col lg="12">
                <b-form-group label="Mensaje:" class="mb-2">
                    <quill-editor :disabled='disabled' ref="quillCorreo" v-model="datosDetalleCorreo.mensaje" :config="quillConfig">
                    </quill-editor>
                </b-form-group>
            </b-col>
            <b-col lg="12" class="text-right my-2">
                <b-button size="md" variant="danger" @click="modalDetalleCorreo = false">
                    Cerrar
                </b-button>
            </b-col>
        </b-row>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import {
    quillEditor
} from 'vue-quill-editor'

export default {
    components: {
        quillEditor
    },
    data() {
        return {
            disabled: false,
            quillConfig: {
                placeholder: 'Escriba aqui...',
            },
            datosCorreo: {
                idCliente: '',
                fecha: moment().format('YYYY-MM-DD'),
                hora: moment().format('HH:mm'),
                destinatarios: '',
                asunto: '',
                mensaje: '',
                archivoMeta: null,
            },

            datosDetalleCorreo: {
                fecha: '',
                hora: '',
                destinatarios: '',
                asunto: '',
                mensaje: '',
                nombreArchivo: '',
                urlArchivo: '',
            },

            listaCorreos: [],
            camposCorreos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "destinatario",
                    label: "Destinatarios",
                    class: "text-center",
                },
                {
                    key: "fecha",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "asunto",
                    label: "Asunto",
                    class: "text-center",
                },
                {
                    key: "mensaje",
                    label: "Mensaje",
                    class: "text-center",
                },
                {
                    key: "tipo",
                    label: "Tipo",
                    class: "text-center",
                },

                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            modalRegistrarCorreo: false,
            modalDetalleCorreo: false,
        }
    },
    methods: {
               checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 5242880) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 5MB");
                    this.$refs["file"].reset();
                    return;
                }

                this.datosCorreo.archivoMeta = this.$refs.file.files[0];
            }
        },

        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        registrarCorreo() {
            let me = this;
            me.disabled = true;
            const formData = new FormData();

            /*---Necesario para ruta de archivo --*/
            formData.append("file", me.datosCorreo.archivoMeta);

            formData.append("folder", 'Correos/ControlCorreos');
            /*---Datos del objeto--*/
            formData.append("idCliente", me.datosCorreo.idCliente);
            formData.append("fecha", me.datosCorreo.fecha);
            formData.append("hora", me.datosCorreo.hora);
            formData.append("destinatarios", me.datosCorreo.destinatarios);
            formData.append("asunto", me.datosCorreo.asunto);
            formData.append("mensaje", me.datosCorreo.mensaje);
            formData.append("tipo", 1);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-correo",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalRegistrarCorreo = false;
                    me.listarCorreos();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },
        descargarDocumento(url) {
            window.open(url)
        },
        abrirModalDetalleCorreo(param) {
            this.datosDetalleCorreo.destinatario = param.item.destinatario;
            this.datosDetalleCorreo.fecha = param.item.fecha;
            this.datosDetalleCorreo.hora = param.item.hora;

            this.datosDetalleCorreo.asunto = param.item.asunto;
            this.datosDetalleCorreo.mensaje = param.item.mensaje;
            this.datosDetalleCorreo.nombreArchivo = param.item.nombreArchivo;
            this.datosDetalleCorreo.urlArchivo = param.item.urlArchivo;

            this.modalDetalleCorreo = true;
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        resetModalRegistrarCorreo() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosCorreo.fecha = moment().format('YYYY-MM-DD');
            this.datosCorreo.hora = moment().format('HH:mm');
            this.datosCorreo.destinatarios = '';
            this.datosCorreo.asunto = '';
            this.datosCorreo.mensaje = '';
            this.datosCorreo.archivoMeta = null;
        },
        listarCorreos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-correos", {
                        params: {
                            idCliente: me.datosCorreo.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaCorreos = response.data;
                    me.filasTotales = me.listaCorreos.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarCorreo(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el correo?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-correo", {
                            idCorreo: param.item.idCorreo,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarCorreos();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    watch: {
        modalRegistrarCorreo: function (val) {
            if (val == false) {
                this.resetModalRegistrarCorreo();
            }
        },
    },
    mounted() {
          if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosCorreo.idCliente = user.uidClient;
            this.listarCorreos();
        }
    }

}
</script>
